import React, { useEffect, useState } from "react";
import axios from "axios";
import NFTmodal from "../components/forms/NFTForm";
import Pagination from "@mui/material/Pagination";

const etherPrice = 2359.84;

const BuyNFT = () => {
  const [nfts, setNfts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to chunk the array into subarrays of 5
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  // Fetch the data when the component is mounted
  useEffect(() => {
    const fetchNFTData = async () => {
      try {
        const response = await axios.get(
          "https://api.simplehash.com/api/v0/nfts/collection/510139ec0d8cf5513d59a8d3afa1c061",
          {
            headers: {
              "x-api-key": "bigsat309_sk_h8salj1y2bj32zi670qe9hzo0gws10d0",
            },
          }
        );

        setNfts(response.data.nfts);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch NFT data");
        setLoading(false);
      }
    };

    fetchNFTData();
  }, []);

  if (loading) return <p style={{ display: 'flex', fontSize: "25px", justifyContent: 'center', alignItems: "center", color: "white" , textAlign: "center", height: '70%' }}>Loading NFTs...</p>;
  if (error) return <p>{error}</p>;

  const paginatedNfts = nfts.slice((page - 1) * 10, page * 10);
  const chunkedNFTs = chunkArray(paginatedNfts, 5);

  return (
    <div className="buy_nfts_container">
      <div className="nfts_container">
        {chunkedNFTs.map((nftColumn, columnIndex) => (
          <div className="nfts_group" key={columnIndex}>
            {nftColumn.map((nft, index) => (
              <NFTmodal
                key={index}
                nft={nft.image_url}
                title={nft.name}
                group_tag={`# ${nft.rarity.rank}`}
                price={`${
                  parseFloat(nft.collection.floor_prices[0].value_usd_cents)
                    ? (
                        parseFloat(
                          nft.collection.floor_prices[0].value_usd_cents
                        ) /
                        etherPrice /
                        100
                      ).toFixed(3)
                    : "N/A"
                }`}
                lastSale={`${
                  parseFloat(nft.last_sale.unit_price_usd_cents)
                    ? (
                        parseFloat(nft.last_sale.unit_price_usd_cents) /
                        etherPrice /
                        100
                      ).toFixed(3)
                    : "N/A"
                }`}
                chain={nft.chain} // New prop for chain
                contract_address={nft.contract_address} // New prop for contract address
                token_id={nft.token_id} // New prop for token ID
              />
            ))}
          </div>
        ))}
      </div>
      <Pagination
        count={Math.ceil(nfts.length / 10)}
        variant="outlined"
        color="primary"
        page={page}
        onChange={handleChange}
        className="nfts_paginator"
      />
    </div>
  );
};

export default BuyNFT;
