import React from "react";
import { Link } from "react-router-dom";
import vectorX from "../assets/icons/VectorX.svg";
import vectorT from "../assets/icons/VectorT.svg";
import vectorY from "../assets/icons/VectorY.svg";
import vectorI from "../assets/icons/VectorI.svg";
import vectorF from "../assets/icons/VectorF.svg";
import "../App.css";

const SideBar = () => {
    return (
      <div className="sidebar-container">
        <Link to="https://www.twitter.com" className="facebook" target="_blank">
          <img src={vectorX} alt="twitter" />
        </Link>
        <Link
          to="https://www.facebook.com"
          className="facebook"
          target="_blank"
        >
          <img src={vectorF} alt="twitter" />
        </Link>
        <Link
          to="https://www.instagram.com"
          className="instagram"
          target="_blank"
        >
          <img src={vectorI} alt="twitter" />
        </Link>
        <Link to="https://www.youtube.com" className="youtube" target="_blank">
          <img src={vectorY} alt="twitter" />
        </Link>
        <Link
          to="https://www.telegram.org"
          className="telegram"
          target="_blank"
        >
          <img src={vectorT} alt="twitter" />
        </Link>
      </div>
    );
}

export default SideBar;