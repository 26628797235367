import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../App.css";

const TextInputForm = ({ required, title, placeholder, border, value, updateValue, type, readonly }) => {
  const [inputtedValue, setValue] = useState(value);

  const handleChange = (e) => {
    setValue(e.target.value);
    updateValue(e.target.value)
  };

  return (
    <div className="text-input-form">      
      <label className="field-title">
        {title}
        {required && <span className="required-field">(Required)</span>}
      </label>
      <input
        type={type}
        value={inputtedValue}
        onChange={handleChange}
        placeholder={placeholder}
        className={`text-input ${border ? "bordered" : ""}`}
        required={required}
        readOnly={readonly}
      ></input>
    </div>
  );
};

TextInputForm.propTypes = {
  required: PropTypes.bool,
  // title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  border: PropTypes.bool,
};

export default TextInputForm;