import React, {useState} from "react";
import axios from "axios";
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import { useNavigate } from "react-router-dom";
import TextInputForm from "../components/forms/textInput.tsx";
import { RememberMeCheckBox } from "../components/forms/checkbox.tsx";
import { AgreeCheckBox } from "../components/forms/checkbox.tsx";
import GoolgeSignInBtn from "../components/buttons/google_signin_btn.tsx";
import "../App.css";
import * as Network from "../network";
import * as Const from "../network/const";

const SignUp = ({ history }) => {

  const navigate = useNavigate();
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const notifySuccess = () => {
    Store.addNotification({
      title: "Register Success!",
      message: `You have succesfully registered! Mr. ${firstName}`,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      },
      onNotificationRemoval: () => this.remove()
    });
  };

  const notifyError = (msg) => {
    Store.addNotification({
      title: "Register Failed!",
      message: msg,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      },
      onNotificationRemoval: () => this.remove()
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents default form submission behavior

    const name = firstName + lastName;

    const userData = {
      name: name,
      email: email,
      password: password
    }

    Network.postRequest(Const.REGISTER_URL, {}, userData, (response) => {
      const data = response.data;
      if (data.status) {
        notifySuccess();
        navigate('/signin'); // Use navigate instead of push
      } else {
          notifyError(data.err_msg);
      }
    });

  };


  return (
    <div className="create-account-container">
      <h1 className="create-account-title">Create Account</h1>
      <div className="create-account-input-field-container">
        <div className="create-account-input-row">
          <TextInputForm required={false} title={"First name"} placeholder={""} border={true} value={firstName} updateValue={setFirstName} />
          <TextInputForm required={false} title={"Last name"} placeholder={""} border={true} value={lastName} updateValue={setLastName} />
        </div>
        <div className="create-account-input-row">
          <TextInputForm required={false} title={"Email address"} placeholder={""} border={true} value={email} updateValue={setEmail} />
          <TextInputForm required={false} title={"Date of Birth"} placeholder={""} border={true} value={birthDate} updateValue={setBirthDate} />
        </div>
        <div className="create-account-input-row">
          <TextInputForm required={false} title={"Password"} placeholder={""} border={true} value={password} updateValue={setPassword} />
          <TextInputForm required={false} title={"Confirm password"} placeholder={""} border={true} value={password2} updateValue={setPassword2} />
        </div>
      </div>
      <RememberMeCheckBox />
      <AgreeCheckBox />
      <div className="create-account-btns-container">
        <GoolgeSignInBtn />
        <div className="create-account-submitbtn" onClick={handleSubmit}>
          Create Account
        </div>
      </div>
    </div>
  );
};

export default SignUp;
