import { getUserInfo } from  "./authData.js";
import axios from "axios";

export function getRequest(url, headers, params, callback) {
    headers.token = JSON.parse(getUserInfo())?.apiToken;
    axios.get(url, params, headers)
    .then(function (response) {
        callback(response);
    })
    .catch(function (error) {
        const data = error.response.data.errors;
        console.log("failed: ", data.toLocaleString());
        callback({
            data: {
                res: "failed",
                err_msg: JSON.stringify(data)
            }
        })
    });
}

export function getRequestNoAuth(url, headers, params, callback) {
    axios.get(url, params, headers)
    .then(function (response) {
        callback(response);
    })
    .catch(function (error) {
        const data = error.response.data.errors;
        console.log("failed: ", data.toLocaleString());
        callback({
            data: {
                res: "failed",
                err_msg: JSON.stringify(data)
            }
        })
    });
}

export function postRequest(url, headers, params, callback) {
    headers.token = JSON.parse(getUserInfo())?.apiToken;    
    axios.post(url, params, headers)
    .then(function (response) {
        callback(response);
    })
    .catch(function (error) {
        const data = error.response.data;
        console.log("failed: ", data.toLocaleString());
        callback({
            data: {
                res: "failed",
                err_msg: JSON.stringify(data)
            }
        })
    });
}

export function postRequestNoAuth(url, headers, params, callback) {
    axios.post(url, params, headers)
    .then(function (response) {
        callback(response);
    })
    .catch(function (error) {
        console.log("failed: ", error.toLocaleString());
        callback({
            data: {
                res: "failed",
                err_msg: error.toLocaleString()
            }
        })
    });
}

export function multiPartFormRequest(url, headers, params, callback) {
    headers.token = JSON.parse(getUserInfo())?.apiToken;
    let file = params["file"];
    let formData = new FormData();
    if (file && file.name) {
        formData.append("document", file);
    }
    Object.keys(params).forEach(function(key) {
        if (key !== "file") {
            formData.append(key, params[key]);
        }
    });
    headers['Content-Type'] = 'multipart/form-data';
    headers['Accept'] = '*/*';
    axios.post(url,
        formData,
        {
            headers: headers
        })
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            console.log("failed: ", error.toLocaleString());
            callback({
                data: {
                    res: "failed",
                    err_msg: error.toLocaleString()
                }
            })
        });
}

export function multiPartFormRequestMultipleFiles(url, headers, params, callback) {
    headers.token = JSON.parse(getUserInfo())?.apiToken;
    let files = params["files"] ?? "";
    let formData = new FormData();
    for (const file of files) {
        formData.append("documents", file);
    }
    Object.keys(params).forEach(function(key) {
        if (key !== "files") {
            formData.append(key, params[key]);
        }
    });
    headers['Content-Type'] = 'multipart/form-data';
    headers['Accept'] = '*/*';
    axios.post(url,
        formData,
        { headers: headers }
    )
    .then(function (response) {
        callback(response);
    })
    .catch(function (error) {
        console.log("failed: ", error.toLocaleString());
        callback({
            data: {
                res: "failed",
                err_msg: error.toLocaleString()
            }
        })
    });
}

