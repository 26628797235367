import React from "react";
import "../../App.css";

const NFTmodal = ({
  nft,
  title,
  group_tag,
  price,
  lastSale,
  chain,
  contract_address,
  token_id,
}) => {
  // Construct the OpenSea URL based on the NFT data
  const openSeaUrl = `https://opensea.io/assets/${chain}/${contract_address}/${token_id}`;

  return (
    <div
      className="nft_container"
      onClick={() => window.open(openSeaUrl, "_blank")}
      style={{ cursor: "pointer" }}
    >
      <div className="nft_image_container">
        <img src={nft} className="nft_url" alt={title} />
      </div>
      <div className="nft_description">
        <div className="nft_group">
          <div className="nft_title">{title}</div>
          <div className="nft_tag">{group_tag}</div>
        </div>
        <div className="nft_price">{price} ETH</div>
        <div className="nft_details">Last Sale: {lastSale} WETH</div>
      </div>
    </div>
  );
};

export default NFTmodal;
