import React from "react";
import Header from "./components/header.jsx";
import Sidebar from "./components/sidebar.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home.jsx";
import Login from "./pages/login.jsx";
import SignUp from "./pages/signup.jsx";
import { ContactUs } from "./pages";
import Profile from "./pages/profile.jsx";
import EditProfile from "./pages/editProfile.jsx";
import PhoneVerification from "./pages/phoneVerification.jsx";
import IDVerification from "./pages/idVerification.jsx";
import ResetPassword from "./pages/resetPassword.jsx";
import { BuyNFT } from "./pages";
import "./App.css";

function App() {
  return (
    <div className="main-container">
      <Router>
        <Header />
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/phone-verification" element={<PhoneVerification />} />
          <Route path="/id-verification" element={<IDVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/NFTs" element={<BuyNFT />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
