import React, { useState } from "react";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useNavigate } from "react-router-dom";
import TextInputForm from "../components/forms/textInput.tsx";
import { Link } from "react-router-dom";
import { RememberMeCheckBox } from "../components/forms/checkbox.tsx";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import googleLogo from "../assets/Google-Logo.png";
import * as Network from "../network";
import * as Const from "../network/const.js";
import { setUserInfo } from "../network/authData.js"

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const notifySuccess = () => {
    Store.addNotification({
      title: "Login Success!",
      message: "You have successfully logged in!",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  const notifyError = (msg) => {
    Store.addNotification({
      title: "Login Failed!",
      message: msg,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  const handleLoginSuccess = (response) => {
    console.log('Google Sign-In successful', response);
    // Example: Authenticate with your backend using the Google response.
    localStorage.setItem('isAuthenticated', 'true'); // Set authenticated status
    notifySuccess();
    navigate('/profile'); // Navigate to the profile page
  };

  const handleLoginFailure = (response) => {
    console.error('Google Sign-In failed', response);
    notifyError("Google Sign-In failed. Please try again.");
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const userData = {
      email: email,
      password: password,
    }
    if(!email || !password){
      notifyError("Please Input Email & Password!");
      return;
    }
    Network.postRequest(Const.LOGIN_URL, {}, userData, (response) => {
      const data = response.data;      
      if (data.status) { 
        const tmpData = {
          id: data.user.id,
          email: data.user.email,
          name: data.user.name,
          token: data.token,
        }       
        setUserInfo(tmpData);
        localStorage.setItem('isAuthenticated', 'true'); // Set authenticated status

        notifySuccess();
        navigate('/profile');
        window.location.href = '/profile';
      } else {
        notifyError(data.err_msg || "Login failed.");
      }
    });    
  };

  return (
    <GoogleOAuthProvider clientId={"631182143071-4bd22fcujujn269qamb5s6r4c13uthlc.apps.googleusercontent.com"}>
      <div className="login-container">
        <div className="login-welcome-header">Welcome to</div>
        <div className="login-th-dimension">5TH DIMENSION</div>
        <div className="login-span-txt"><span>Log in </span>now to explore the 5th Dimension.</div>
        <div className="login-form">
          <div className="login-input-field">
            <TextInputForm placeholder={"Email"} border={true} value={email} updateValue={setEmail} type="email"/>
            <TextInputForm placeholder={"Password"} border={true} value={password} updateValue={setPassword} type="password"/>
          </div>
          <div className="login-check-options">
            <RememberMeCheckBox />  
            <Link to="/forgot-password" className="login-forgot-password">Forgot Password</Link>
          </div>
          <button type="submit" className="login-button" onClick={handleSubmit}>Login</button>
        </div>
        <div className="divider">
          <div className="horizontalLine" />
          <div className="divideText">Or continue with</div>
          <div className="horizontalLine" />
        </div>
        <div className="google-logo-signin">
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
            render={renderProps => (
              <div className="circleBorder" onClick={renderProps.onClick}>
                <img src={googleLogo} alt="Google Logo" className="LogoGoogle"/> 
              </div>
            )}
          />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
