import React from "react";
import Checkbox from "@mui/material/Checkbox";
import "../../App.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const RememberMeCheckBox = () => {
  return (
    <div className="Check-Box-Container">
      <Checkbox className="check-box" {...label} />
      <label className="Check-Box-Text-Label">Remember me</label>
    </div>
  );
};

export const AgreeCheckBox = () => {
  return (
    <div className="Check-Box-Container">
      <Checkbox {...label} className="check-box" />
      <label className="Check-Box-Text-Label">
        I agree to all the Terms and Privacy policy
      </label>
    </div>
  );
};
