// src/components/Model.js
import React, { Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import * as THREE from "three";

const Model = ({ modelPath, rotation }) => {
  const fbx = useLoader(FBXLoader, modelPath);

  const metalMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    metalness: 1,
    roughness: 0.5,
  });

  useEffect(() => {
    fbx.traverse((child) => {
      if (child.isMesh) {
        if (!child.name.includes("Sphere")) {
          // Assuming spheres have "Sphere" in their name
          child.material = metalMaterial;
        }
      }
    });

    if (rotation) {
      fbx.rotation.set(rotation[0], rotation[1], rotation[2]);
    }

  }, [fbx]);

  // Apply scaling to the model
  useEffect(() => {
    fbx.scale.set(0.006, 0.006, 0.006); // Adjust the scale as needed
  }, [fbx]);



  return <primitive object={fbx} />;
};

const CameraLight = () => {
  const lightRef = useRef();

  useFrame(({ camera }) => {
    if (lightRef.current) {
      lightRef.current.position.copy(camera.position);
    }
  });

  return <directionalLight ref={lightRef} color="white" intensity={4} />;
};

const ModelCanvas = ({ modelPath, initialRotation}) => {
  return (
    <Canvas>
      <ambientLight intensity={1} />
      <CameraLight />
      <Suspense fallback={null}>
        <Model modelPath={modelPath} rotation={initialRotation}/>
      </Suspense>
      <OrbitControls />
    </Canvas>
  );
};

export default ModelCanvas;
