import React from "react";
import ModelCanvas from "../components/3D_model";
import "../App.css";
import modelPath from "../assets/to_export.fbx";

const Home = () => {
    return (
            <div className="home-content">
                <ModelCanvas modelPath={modelPath} initialRotation={[0.9, 0.565, 0]} />
            </div>
      );
};

export default Home;