import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SignInBtn from "../components/buttons/signIn_btn";
import logo from "../assets/logo.svg";
import "../App.css";

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate(); // For redirecting

  // Check authentication status when the component mounts
  useEffect(() => {
    const loggedIn = localStorage.getItem("isAuthenticated") === "true";
    setIsAuthenticated(loggedIn);
  }, []);

  const handleLogout = () => {
    // Clear authentication status and update state
    localStorage.removeItem("isAuthenticated");
    setIsAuthenticated(false);

    // Redirect to home or login page after logout
    navigate("/");
  };

  return (
    <div className="header-container">
      <div className="header-logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="header-links">
        <Link to="/contactus">Contact Us</Link>
        {!isAuthenticated && <Link to="/signup">Sign Up</Link>}
        {!isAuthenticated && (
          <Link to="/signin">
            <SignInBtn />
          </Link>
        )}
        {isAuthenticated && <Link to="/NFTs">Buy NFT</Link>}
        {isAuthenticated && (
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
