import React, { useEffect } from "react";
import "../../App.css";

const GoolgeSignInBtn = () => {
  useEffect(() => {
    //Initialize the Google SignIn button
    window.google.accounts.id.initialize({
      client_id:
        "631182143071-4bd22fcujujn269qamb5s6r4c13uthlc.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    // Render the google Sign In Button
    window.google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        theme: "outline",
        size: "large",
      }
    );
  }, []);

  const handleCallbackResponse = (response) => {
    console.log("Encoded JWT ID Token: " + response.credential);
  };

  return <div id="google-signin-button"></div>;
};

export default GoolgeSignInBtn;
