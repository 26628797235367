import React, { useEffect } from "react";
import "../App.css";
import TextInputForm from "../components/forms/textInput.tsx";
import profileAvatar from "../assets/Profile icon 1 (1).png";
import dollar from "../assets/OBJECTS.png";
import urlIcon from "../assets/Polygon 1.png";
import profile from "../assets/My_Profile.png";
import edit from "../assets/Edit_Profile.png";
import phone from "../assets/Phone_Verification.png";
import id from "../assets/ID_verification.png";
import reset from "../assets/Reset_Password.png";
import pfp from "../assets/pfp.png";
import { getUserInfo } from  "../network/authData.js";

const Profile = () => {
    // const [name, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    const currentUser = JSON.parse(getUserInfo());
    return (
        <div className="profile-container">
            <main className="pro-main-content">
                <div className="profile-header">
                    <div className="profile-wallet">
                        <div className="left-sidebar">
                            <img className="profileAvatar" src={profileAvatar} alt="Avatar" />
                            <div className="subheader-wallet">My Profile</div>
                        </div>
                        <div className="subtitle-wallet">Crypto Wallet <img className="urlIcon" src={urlIcon} alt=">" /><span className="myProfile"> My Profile</span></div>
                    </div>
                    
                    <div className="balance-info">
                        <div className="available-balance">
                            <div className="available-balance-text">
                                <img src={dollar} alt="objects" className="dollarIcon" />
                                <div className="avalable-text">Available Balance</div>
                            </div>
                            <div className="ETH-balance"><span className="balance">00067.00022</span> <span className="token">ETH</span></div>
                            <div className="MIPs-balance"><span className="balance">00067.00022</span> <span className="token">MIPS</span></div>
                        </div>
                        <div className="pending-balance">
                            <div className="pending-balance-text">
                                <img src={dollar} alt="objects" className="dollarIcon" />
                                <div className="pending-text">Pending Balance</div>
                            </div>
                            <div className="ETH-balance"><span className="mips">00067.00022</span> <span className="token">ETH</span></div>
                            <div className="MIPs-balance"><span className="mips">00067.00022</span> <span className="token">MIPS</span></div>
                        </div>
                    </div>
                </div>
                <div className="profile-main-content">
                    <div className="profile-nav">
                        <button className="Profile_Button" >
                            <img className="profile-Icon" src={profile} alt="My_Profile" />
                            <div className="detail-text"> Profile </div>
                        </button>
                        <button className="Edit_Profile" >
                            <img className="edit-profile-Icon" src={edit} alt="Edit_Profile" />
                            <div className="detail-text"> Edit Profile </div>
                        </button>
                        <button className="Phone_verification" >
                            <img className="phone-verify-icon" src={phone} alt="Phone_Verification" />
                            <div className="detail-text"> Phone Verification </div>
                        </button>
                        <button className="ID_verification" >
                            <img className="ID-verify-icon" src={id} alt="ID_Verify" />
                            <div className="detail-text"> ID Verification </div>
                        </button>
                        <button className="Reset_password" >
                            <img className="Reset-Password" src={reset} alt="reset_password" />
                            <div className="detail-text"> Reset Password </div>
                        </button>
                    </div>
                    <div className="navbar-divide-line" />
                    <div className="profile-details">
                        <div className="user-infos">
                            <img src={pfp} alt="user photo" className="user-photo" />
                            <div className="private-infos">
                                <div className="username">{currentUser.name}</div>
                                <div className="userEmail">{currentUser.email}</div>
                                <div className="userNation">United States</div>
                            </div>
                        </div>
                        <form className="profile-form">
                            <div className="input-group">
                                <TextInputForm required={false} title={""} placeholder={"Name"} border={true} value={currentUser.name} readonly/>
                                <TextInputForm required={false} title={""} placeholder={"Email"} border={true} value={currentUser.email} readonly/>
                            </div>
                            
                            <div className="input-group">
                                <TextInputForm required={false} title={""} placeholder={"Role"} border={false} />
                                <TextInputForm required={false} title={""} placeholder={"Email Verification"} border={false} />
                            </div>
                            <div className="input-group">
                                <TextInputForm required={false} title={""} placeholder={"Contact"} border={true} />
                                <TextInputForm required={false} title={""} placeholder={"Mobile Verification"} border={true} />
                            </div>
                            <div className="profile-status-input-element">
                                <TextInputForm required={false} title={""} placeholder={"Status"} border={false} />
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    )
};

export default Profile;
