import React from "react";
import photo from "../../assets/icons/pf1.svg";
import "../../App.css";

const SignInBtn = () => {
    return (
        <div className = "signIn_button">
            <div className = "signIn_user">
                <img src={photo} alt="profile photo" />
            </div>
            <div className = "signIn_txt">
                Sign In
            </div>
        </div>
    )
}

export default SignInBtn;